<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        {{ !selectedItem.lbl_id ? "Add " : "Update "
        }}{{ masterInfo.childern.title }}
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <b-form class="p-2" @submit.prevent="submitForm">
      <b-form-group
        v-if="masterInfo && masterInfo.childern && masterInfo.childern.lbl_name"
        :label="masterInfo.childern.lbl_name"
        label-for="lbl_name_child"
      >
        <b-form-input
          v-model="form.lbl_name"
          id="lbl_name_child"
          :placeholder="masterInfo.lbl_name"
        />
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.lbl_value1"
        :label="masterInfo.lbl_value1"
        label-for="lbl_value1_child"
      >
        <b-form-input
          v-model="form.lbl_value1"
          id="lbl_value1_child"
          :placeholder="masterInfo.lbl_value1"
        />
      </b-form-group>

      <b-form-group
        v-if="
          masterInfo && masterInfo.childern && masterInfo.childern.lbl_value2
        "
        :label="masterInfo.childern.lbl_value2"
        label-for="lbl_value2_child"
      >
        <b-form-input
          v-model="form.lbl_value2"
          id="lbl_value2_child"
          :placeholder="masterInfo.lbl_value2"
        />
      </b-form-group>

      <b-form-group
        v-if="masterInfo && masterInfo.childern && masterInfo.childern.status"
        :label="masterInfo.childern.status"
        label-for="status_child"
      >
        <b-form-checkbox
          v-model="form.status"
          class="custom-control-primary"
          id="status_child"
          switch
          :unchecked-value="0"
        >
          <span class="switch-icon-left">
            <!-- <feather-icon icon="BellIcon" /> -->
          </span>
          <span class="switch-icon-right">
            <!-- <feather-icon icon="BellOffIcon" /> -->
          </span>
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        v-if="
          masterInfo &&
          masterInfo.childern &&
          masterInfo.childern.revenue_upload_validation
        "
        :label="masterInfo.childern.revenue_upload_validation"
        label-for="revenue_upload_validation_child"
      >
        <b-form-checkbox
          v-model="form.revenue_upload_validation"
          class="custom-control-primary"
          id="revenue_upload_validation_child"
          switch
          :unchecked-value="0"
        >
          <span class="switch-icon-left"> </span>
          <span class="switch-icon-right"> </span>
        </b-form-checkbox>
      </b-form-group>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mb-1 mt-3"
        block
      >
        Submit
      </b-button>
      <!-- <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        variant="outline-secondary"
        block
      >
        Reset
      </b-button> -->
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { addData, updateData } from "@/apiServices/MastersServices";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ToastificationContentVue,
  },
  directives: {
    Ripple,
  },

  props: {
    masterInfo: Object,
    parentInfo: Object,
    selectedItem: Object,
    closeSidebar: Function,
  },

  data() {
    return {
      form: {
        lbl_cat: null,
        lbl_id: null,
        lbl_name: null,
        lbl_value1: null,
        lbl_value2: null,
        revenue_upload_validation: null,
        status: null,
      },
    };
  },

  watch: {
    selectedItem() {
      if (this.masterInfo && this.selectedItem) {
        this.form = {
          ...this.selectedItem,
          status: !this.selectedItem.lbl_id
            ? true
            : this.selectedItem.status == 1
            ? true
            : false,
          revenue_upload_validation:
            this.selectedItem.revenue_upload_validation == 1 ? true : false,
        };
      }
    },
  },

  methods: {
    async submitForm() {
      let response = null;
      if (!this.form.lbl_id) {
        response = await addData(this.masterInfo.childern.lbl_cat, {
          ...this.form,
          lbl_value1: this.parentInfo.lbl_id,
          lbl_cat: this.masterInfo.childern.lbl_cat,
          status: this.form.status == true ? 1 : 0,
          revenue_upload_validation:
            this.form.revenue_upload_validation == true ? 1 : 0,
        });
      } else {
        response = await updateData(
          this.selectedItem.lbl_cat,
          {
            ...this.form,
            status: this.form.status == true ? 1 : 0,
            revenue_upload_validation:
              this.form.revenue_upload_validation == true ? 1 : 0,
          },
          this.form.lbl_id
        );
      }

      if (response) {
        if (response.data.message) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.closeSidebar ? this.closeSidebar() : null;
          this.$emit("refreshList");
        }
        if (response.data.error) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.error || "",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Something went wrong",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        this.closeSidebar ? this.closeSidebar() : null;
      }
    },
  },
};
</script>
