<template>
  <div>
    <b-card no-body class="mb-0" :title="masterInfo.childern.title">
      <b-table
        bordered
        class="position-relative"
        :items="tableData"
        responsive
        :fields="tableColumns"
        primary-key="lbl_id"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Status column -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="
              data.item.deleted == 1
                ? 'light-danger'
                : data.item.status == 1
                ? 'light-success'
                : 'light-warning'
            "
            class="badge-glow"
          >
            {{
              data.item.deleted == 1
                ? "Deleted"
                : data.item.status == 1
                ? "Active"
                : "Inactive"
            }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span v-if="data.item.deleted == true">
            <feather-icon
              v-if="$can('delete', 'master')"
              class="ml-1"
              size="18"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.right.v-primary
              variant="outline-primary"
              title="Restore"
              @click="() => setDeleteItem(data.item.lbl_id, 0)"
              icon="RotateCcwIcon"
            />
          </span>
          <span v-else>
            <feather-icon
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Edit"
              @click="
                () => {
                  openForm = true;
                  selectedItem = data.item;
                }
              "
              icon="EditIcon"
            />
            <feather-icon
              v-if="$can('delete', 'master')"
              class="ml-1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-primary
              variant="outline-primary"
              title="Delete"
              @click="() => showDeleteConfirm(data.item.lbl_id)"
              icon="Trash2Icon"
            />
          </span>
        </template>
      </b-table>
      <template #footer>
        <div>
          <b-row class="d-flex align-items-center justify-content-center">
            <b-col class="mb-1">
              <b-form-checkbox
                v-model="deleted"
                name="check-button"
                switch
                inline
                @input="showDeleteToggled"
              >
                Show Deleted
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-center">
                <b-button
                  v-if="$can('create', 'master')"
                  block
                  variant="outline-primary"
                  @click="
                    () => {
                      openForm = true;
                      selectedItem = {};
                    }
                  "
                >
                  <span class="text-nowrap"
                    >Add {{ masterInfo.childern.title }}</span
                  >
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>

    <b-sidebar
      id="sub-master-form"
      v-model="openForm"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
    >
      <div class="">
        <SubMasterForm
          @refreshList="getData"
          :masterInfo="masterInfo"
          :parentInfo="parentInfo"
          :selectedItem="selectedItem"
          :closeSidebar="hide"
        />
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSidebar,
  VBPopover,
  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import SubMasterForm from "./SubMasterForm.vue";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { getValueData, deleteLabel } from "@/apiServices/MastersServices";
import Ripple from "vue-ripple-directive";

export default {
  props: { masterInfo: Object, parentInfo: Object },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
    Ripple,
  },

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,
    vSelect,
    BSidebar,
    SubMasterForm,
    VBTooltip,
    ToastificationContentVue,
    BFormCheckbox,
  },

  data() {
    return {
      selectedItem: {},
      deleted: false,

      currentPage: 0,
      totalPages: 0,
      limit: 10,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],

      tableColumns: [],
      search: "",
      tableData: [],
      openForm: false,
    };
  },

  watch: {
    masterInfo() {
      this.changeColumns();
      this.getData();
    },
    search() {
      this.getData();
    },
    limit() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },

  methods: {
    showDeleteToggled() {
      this.$nextTick(() => {
        this.getData();
      });
    },
    async setDeleteItem(id, deleted) {
      const response = await deleteLabel({ lbl_id: id, deleted });
      if (response.data.status == true) {
        this.$nextTick(() => {
          this.getData();
        });
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
      }
      return this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Delete Failed",
          icon: "EditIcon",
          variant: "failure",
        },
      });
    },
    showDeleteConfirm(selected_id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure, you want to delete?", {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) this.setDeleteItem(selected_id, 1);
        })
        .catch((err) => {
          // An error occurred
        });
    },

    async getData() {
      const response = await getValueData(
        this.masterInfo.childern.lbl_cat,
        this.parentInfo.lbl_id,
        this.deleted ? 1 : 0
      );
      if (response.status == 200) {
        this.tableData = response.data.data.rows;
        this.totalRows = response.data.data.count;
      }
    },

    hide() {
      this.openForm = false;
      this.selectedItem = {};
    },

    changeColumns() {
      let column = [];

      Object.keys(this.masterInfo.childern).map((key) => {
        if (key == "lbl_name") {
          column.push({
            label: this.masterInfo.childern.lbl_name,
            key: key,
            sortable: true,
          });
        }
        if (key == "lbl_value1") {
          column.push({
            label: this.masterInfo.childern.lbl_value1,
            key: key,
            sortable: true,
          });
        }
        if (key == "lbl_value2") {
          column.push({
            label: this.masterInfo.childern.lbl_value2,
            key: key,
            sortable: true,
          });
        }
        if (key == "status") {
          column.push({
            label: this.masterInfo.childern.status,
            key: key,
            sortable: true,
          });
        }
      });
      if (this.$can("update", "master")) {
        column.push({ key: "actions" });
      }
      this.tableColumns = column;
    },
  },
  beforeMount() {
    if (this.masterInfo) {
      this.changeColumns();
      this.getData();
    }
  },
};
</script>
