export const masters = [
  {
    title: "Currencies",
    lbl_cat: "currency",
    lbl_name: "Currency Name",
    lbl_value1: "Short Code",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    // icon: "UserIcon",
    icon: "DollarSignIcon",
  },
  {
    title: "Tax",
    lbl_cat: "tax",
    lbl_name: "Tax Name",
    lbl_value1: "Percentage/Fixed",
    lbl_value1_input_type: "select",
    lbl_value1_input_options: ["Percentage", "Fixed"],
    lbl_value2: "Value",
    lbl_value3: "Accounting Code",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "PercentIcon",
  },
  {
    title: "Miscellaneous Revenues & Expenses",
    lbl_value1: "Value",
    lbl_value1_input_type: "select",
    lbl_value1_input_options: [
      "miscellaneous_revenue",
      "miscellaneous_expense",
    ],
    lbl_cat: "revenue_types",
    lbl_name: "Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "TrendingUpIcon",
  },
  // {
  //   title: "Contribution Masters",
  //   lbl_cat: "contribution_masters",
  //   lbl_name: "Contribution Masters",
  //   status: "Status",
  //   order: "Order",
  //   actions: ["edit"],
  //   icon: "FileIcon",
  // },
  {
    title: "Contract Types",
    lbl_cat: "contract_type",
    lbl_name: "Contract Type Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileTextIcon",
  },
  {
    title: "Rights",
    lbl_cat: "rights",
    lbl_name: "Rights Name",
    // lbl_value1: "Rights Category",
    lbl_value1_input_type: "select",
    lbl_value1_input_options: ["Theatrical", "Other"],
    status: "Rights Status",
    // revenue_upload_validation: "Revenues Upload Validation",
    order: "Order",
    actions: ["edit"],
    icon: "AnchorIcon",
    childern: {
      title: "Subrights",
      lbl_cat: "sub_right",
      lbl_name: "Subright Name",
      status: "Status",
      revenue_upload_validation: "Check for Revenues Upload",
      actions: ["edit"],
      icon: "AnchorIcon",
    },
  },
  {
    title: "Territories",
    lbl_cat: "territory",
    lbl_name: "Territory Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "GridIcon",
    childern: {
      title: "Sub-territories",
      lbl_cat: "sub_territory",
      lbl_name: "Sub Territory Name",
      status: "Status",
      actions: ["edit"],
      icon: "GridIcon",
    },
  },
  {
    title: "Revenues Share Types",
    lbl_cat: "revenue_share",
    lbl_name: "Name",
    lbl_value2: "Description",
    lbl_value2_input_type: "textarea",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "TrendingUpIcon",
  },
  {
    title: "Milestones",
    lbl_cat: "milestone",
    lbl_name: "Milestone Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "MapIcon",
  },
  {
    title: "Bumpers",
    lbl_cat: "bumper",
    lbl_name: "Bumper Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "DribbbleIcon",
  },
  {
    title: "Directors",
    lbl_cat: "director",
    lbl_name: "Director Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "UserIcon",
  },
  {
    title: "Cast",
    lbl_cat: "cast",
    lbl_name: "Cast",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "CastIcon",
  },
  {
    title: "Countries",
    lbl_cat: "country",
    lbl_name: "Country Name",
    status: "Status",
    actions: ["edit"],
    order: "Order",
    icon: "FlagIcon",
  },
  {
    title: "Genres",
    lbl_cat: "genre",
    lbl_name: "Genre",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "GithubIcon",
  },
  {
    title: "Types",
    lbl_cat: "type",
    lbl_name: "Type",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "TypeIcon",
  },
  {
    title: "Languages",
    lbl_cat: "language",
    lbl_name: "Language Name",
    lbl_value1: "Short Code",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "GlobeIcon",
  },
  {
    title: "Age / Ratings",
    lbl_cat: "age",
    lbl_name: "Age / Rating",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "StarIcon",
  },
  {
    title: "Aspect Ratios",
    lbl_cat: "aspect_ratio",
    lbl_name: "Aspect Ratio",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "ServerIcon",
  },
  {
    title: "Sound Formats",
    lbl_cat: "sound_format",
    lbl_name: "Sound Format",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "Volume1Icon",
  },
  {
    title: "Image Formats",
    lbl_cat: "image_format",
    lbl_name: "Image Format",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "ImageIcon",
  },
  {
    title: "Distributors",
    lbl_cat: "distributor",
    lbl_name: "Distributor Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "UsersIcon",
  },
  {
    title: "Contact Types",
    lbl_cat: "contact_type",
    lbl_name: "Contact Type Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "PhoneIcon",
  },
  {
    title: "Interests",
    lbl_cat: "interest",
    lbl_name: "Interest Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "PercentIcon",
  },
  // {
  //   title: "File Type",
  //   lbl_cat: "file_type",
  //   lbl_name: "File Type",
  //   status: "Status",
  //   order: "Order",
  //   actions: ["edit"],
  //   icon: "FileIcon",
  // },
  {
    title: "Material Physical",
    lbl_cat: "material_physical",
    lbl_name: "Material Physical",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Material Digital",
    lbl_cat: "material_digital",
    lbl_name: "Material Digital",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Material Language Version",
    lbl_cat: "material_language_version",
    lbl_name: "Material Language Version",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Language Booking Code",
    lbl_cat: "language_booking_code",
    lbl_name: "Language Booking Code",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Depot Portal",
    lbl_cat: "depot_portal",
    lbl_name: "Depot Portal",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Media Types",
    lbl_cat: "media_type",
    lbl_name: "Media Type Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "TvIcon",
  },
  {
    title: "Labels",
    lbl_cat: "label",
    lbl_name: "Label Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "PenToolIcon",
  },
  {
    title: "Pricing Codes",
    lbl_cat: "pricing_code",
    lbl_name: "Pricing Code Name",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "BookIcon",
  },
  {
    title: "Show Code",
    lbl_cat: "show_code",
    lbl_name: "Show Code",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Pricing Method",
    lbl_cat: "pricing_method",
    lbl_name: "Pricing Method",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Screen Definition",
    lbl_cat: "screen_definition",
    lbl_name: "Screen Definition",
    status: "Status",
    order: "Order",
    actions: ["edit"],
    icon: "FileIcon",
  },
  {
    title: "Categories",
    lbl_cat: "categories",
    lbl_name: "Category Name",
    status: "	Category Status",
    order: "Order",
    actions: ["edit"],
    icon: "ListIcon",
    childern: {
      title: "Sub Categories",
      lbl_cat: "sub_category",
      lbl_name: "Sub Category Name",
      status: "Status",
      actions: ["edit"],
      icon: "ListIcon",
    },
  },
];
